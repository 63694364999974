<template>
  <div>
    <!--    状态栏-->
    <tabs @parentEvent="btn"></tabs>

    <div class="pc">
      <div style="background: #f7f7f7;">
        <div class="content">
          <!--          面包屑-->
          <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="breadcrumb"
          >
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/news' }"
              >企业新闻
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '' }">新闻动态</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>

      <div class="item_box">
        <div class="type_page">
          <h1 class="title">
            {{ title }}
          </h1>
          <div class="news_box">
            <!--左边主文-->
            <div class="left">
              <div class="top_box">
                <div class="date">
                  <span>作者：{{ author }}</span
                  ><span>发布时间：{{ time }}</span>
                </div>
              </div>
              <div class="text_box">
                <div v-html="htmls" class="htmls_main"></div>
              </div>
            </div>
            <!--右边公告-->
            <div class="right">
              <div class="top_box">
                <div class="title">热门新闻</div>
              </div>
              <div
                class="ele_boxs"
                @click="routerBtn(item.Id)"
                v-for="(item, i) in list"
                :key="i"
              >
                <div class="img">
                  <img :src="item.Cover" alt="" />
                </div>
                <div class="title">
                  {{ item.Title }}
                </div>
                <div class="detail">
                  {{ item.Brief }}
                </div>
                <div class="more_1">
                  <span class="line"></span>
                  <img src="../../assets/right.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--底部联系我们-->
    <footers id="botton"></footers>
  </div>
</template>

<script>
import tabs from "@/components/tabs";
import footers from "@/components/footers";
export default {
  name: "news_item",
  props: [],
  components: {
    tabs,
    footers
  },

  data() {
    return {
      list: [],
      htmls: "",
      title: "",
      time: "",
      author: ""
    };
  },
  mounted() {
    // 获取后端接口
    let _this = this;
    this.$axios
      .post("https://api.bluexii.cn/xiimol/v1/public/corpcmscontentdetail", {
        // 还可以直接把参数拼接在url后边

        Id: parseInt(this.$route.params.id)
      })
      .then(function(res) {
        console.log(res.data.Data);
        _this.htmls = res.data.Data.Content;
        _this.title = res.data.Data.Title;
        _this.time = res.data.Data.ReleaseAt;
        _this.author = res.data.Data.Author;
      })
      .catch(function(error) {
        console.log(error);
      });
    this.$axios
      .get("https://api.bluexii.cn/xiimol/v1/public/corpcmscontentlist/16/0/10")
      .then(function(res) {
        _this.list = res.data.Data.slice(0, 4);
      })
      .catch(function(error) {
        console.log(error);
      });
  },
  methods: {
    // 跳转到底部
    btn() {
      // document.documentElement.scrollTop= this.height;//不需要加单位
      document.getElementById("botton").scrollIntoView();
    },
    // 右边公告路由跳转
    routerBtn(index) {
      var src = "/news/news_detail/" + index;
      this.$router.push(src);
      location.reload();
    }
  }
};
</script>
<style lang="less" scoped>
.pc {
  margin-top: 65px;
}
.content {
  max-width: 1160px;
  margin: 0 auto;
}

.breadcrumb {
  height: 49px;
  font-size: 12px;
  color: #696969;
  line-height: 49px;
}

.item_box {
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-right: 40px;
  align-items: center;
  padding-top: 50px;

  .type_page {
    width: 1200px !important;

    .title {
      font-size: 22px;
      color: #181818;
      letter-spacing: 0;
      margin-bottom: 10px;
      margin-left: 20px;
    }

    .news_box {
      display: flex;

      .left {
        width: 800px;
        margin-right: 39px;
        padding-left: 20px;

        .top_box {
          border-bottom: 1px solid #e6e6e6;
          padding-bottom: 30px;
          margin-bottom: 30px;

          .date {
            font-size: 14px;
            color: #666;
            line-height: 24px;

            span:nth-child(1) {
              margin-right: 20px;
            }
          }
        }

        .text_box {
          margin-bottom: 80px;
          //text-align: center;
          /deep/p {
            margin: 0;
          }
          /deep/ .wscnph {
            width: 100%;
            height: 100%;
          }
        }
      }

      .right {
        margin-top: -19px;

        .top_box {
          border-bottom: 1px solid #e6e6e6;
          padding-bottom: 33px;
          margin-bottom: 30px;

          .title {
            font-size: 16px;
            color: #181818;
            letter-spacing: 0;
            height: 30px;
            line-height: 40px;
            font-weight: 600;
          }
        }

        .ele_boxs {
          display: block;
          padding: 20px;
          line-height: 28px;
          margin-bottom: 29px;
          cursor: pointer;
          background: 0 0;
          border: 1px solid #f1f2f2;
          width: 300px;

          .img {
            width: 300px;
            height: 124px;

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }

          .title {
            font-size: 16px;
            color: #181818;
            letter-spacing: 0;
            margin: 20px 0 10px;
          }

          .detail {
            width: 305px;
            font-size: 14px;
            color: #999;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-bottom: 30px;
          }

          .more_1 {
            display: flex;
            align-items: center;
            height: 22px;

            .line {
              width: 1px;
              height: 22px;
              opacity: 0.2;
              background: #999;
              margin-right: 25px;
            }

            img {
              width: 25px;
              height: 19px;
              //background-color: #0da0ec;
            }
          }
        }

        .ele_boxs:hover {
          background: #fff;
          box-shadow: 0 0 40px 0 rgba(27, 31, 64, 0.1);
        }
      }
    }
  }
}
</style>
